@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@font-face {
  font-family: 'PPNeueMontreal';
  src: url('../public/PPNeueMontreal-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'EuclidCircularA';
  src: url('../public/EuclidCircularA-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'TTHovesPro';
  src: url('../public/TTHovesPro.ttf') format('truetype');
}

:root {
  --color-primary: #F4E132;
  --color-primary-bg: #fcf2c7;
  --color-primary-alt: #dad179;
  --color-primary-bg-hover: #FFFBE6;
  --color-danger: #E02E2E;
  --color-action: #23A146;
  --color-action-light: #31bd59;
  
  --color-main: #0D0E14;
  --color-main-light: #34384f;

  --color-dark-mode: #141414;
  --color-dark-mode-light: #8A8F8F;
  --color-hover-dark: #3B3B3B;
  --color-hover-dark-light: #4c4c4c;

  --color-subheading: #383B42;
  --color-subheading-light: #595E69;
  --color-offwhite-bg: #FAFAFA;
  
  --color-gray: #757F7F;
  --color-lightgray: #EDEDED;
  --color-divider: #E8E9EA;
  --color-midgray: #dadada;
  --color-heading: #151F20;
  --color-empty: #F8F8F8;
  --color-required: #FF0825;

  
  --color-completed: #25700B;
  --color-completed-bg: #DDFCE0;
  --color-progress: #4e42bc;
  --color-progress-bg: #E4DDFC;
  --color-incomplete: #E27C1E;
  --color-incomplete-bg: #FFF7ED;
  --color-closed: #AD3D6C;
  --color-closed-bg: #FCDDEA;

  --color-reject: #E02E2E;
  --color-reject-bg: #F8D5D5;
  --color-mid: #E27C1E;
  --color-mid-bg: #FFF7ED;
  --color-accept: #25700B;
  --color-accept-bg: #DDFCE0;

  --color-assistant: #5F3DE7;
  --color-assistant-dark: #352281;
}

* {
  box-sizing: border-box;
}

body {
    background-color: #F8F8F8;
    font-family: 'PPNeueMontreal', sans-serif;
    color: black;
}

h1, p, h2, h3, h4, h5, h6 {
  font-family: 'PPNeueMontreal';
  color: var(--color-heading);
}

h3 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

.font-circular {
  font-family: 'EuclidCircularA' !important;
}

.font-inter {
  font-family: 'Inter' !important;
}

.lh-20 {
  line-height: 20px;
}

.clickable-grow {
  cursor: pointer;
  transition: 0.2s;
}
.clickable-grow:hover {
  transform: scale(1.025);
}

.detail-cols-wrapper {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 148px);
  overflow: hidden;
  background-color: white;
}

.main-details {
  padding: 24px;
  flex: 5;
}

.side-details {
  padding: 24px;
  flex: 4;
  border-left: 1px solid lightgray;
}

.iframe-cta {
  border: 1px solid var(--color-divider);
  box-shadow: 0px 0px 24px -4px #151f2030;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .detail-cols-wrapper {
    flex-direction: column;
    height: 148vh;
    overflow: scroll;
    background-color: white;
  }
  
  .main-details {
    height: min-content;
    flex: 0;
  }

  .side-details {
    height: 90vh;
  }
}

.wrap-grid {
	display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
	min-width: 100%;
}

.sidebar-right {
  position: fixed;
  top: 8px;
  right: -560px;
  height: calc(100vh - 16px);
  width: 548px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background: white;
  transition: 0.3s ease-in-out all;
}
.sidebar-right-show {
  right: 8px;
}

.clickable {
  cursor: pointer;
}

.hoverable {
  cursor: pointer;
  transition: all 0.2s !important;
  transform: scale(1);
}

.hoverable:hover {
  transform: scale(1.2);
}

.card {
  border: 1px solid lightgray;
  border-radius: 6px;
  color: var(--color-main);
  background-color: white;
  transition: 0.2s;
}

.card.clickable:hover, .card.clickable.selected {
  border: 1px solid var(--color-gray);
  background-color: var(--color-lightgray);
  transform: scale(1.025);
}

.card.clickable.selected {
  background-color: var(--color-lightgray) !important;
}

.section-heading, .section-body, .input-label {
  margin: 0 0 8px 0;
}

.m-0 {
  margin: 0;
}

.section-sm {
  margin-bottom: 16px;
}

.section {
  margin-bottom: 24px;
}

.inactive {
  opacity: 0.5;
}

.color-primary {
  color: var(--color-primary);
}

.color-main {
  color: var(--color-main);
}

.color-main-light {
  color: var(--color-main-light);
}

.color-danger {
  color: var(--color-danger);
}

.color-subheading {
  color: var(--color-subheading);
}

.color-subheading-light {
  color: var(--color-subheading-light);
}

.color-gray {
  color: var(--color-gray);
}

.color-midgray {
  color: var(--color-midgray);
}

.color-mid {
  color: var(--color-mid);
}

.color-heading {
  color: var(--color-main);
}

.color-required {
  color: var(--color-required);
}


.color-reject {
  color: var(--color-reject);
}
.color-mid {
  color: var(--color-mid);
}
.color-accept {
  color: var(--color-accept);
}
.color-reject-bg {
  background-color: var(--color-reject-bg);
}
.color-mid-bg {
  background-color: var(--color-mid-bg);
}
.color-accept-bg {
  background-color: var(--color-accept-bg);
}


.color-completed {
  color: var(--color-completed);
}
.color-progress {
  color: var(--color-progress);
}
.color-closed {
  color: var(--color-closed);
}
.color-completed-bg {
  background-color: var(--color-completed-bg);
}
.color-progress-bg {
  background-color: var(--color-progress-bg);
}
.color-closed-bg {
  background-color: var(--color-closed-bg);
}

.color-main-bg {
  background-color: var(--color-main);
}

.color-empty-bg {
  background-color: var(--color-empty);
}

.headerP {
  font-family: 'TTHovesPro', sans-serif;
  font-size: 38;
}

.rap-pp-button {
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 50%;
	background-color: var(--color-lightgray);
	color: var(--color-main);
}

.rap-pp-icon {
  margin: auto;
	color: var(--color-main);
}
.rap-pin {
  width: 12px !important;
  height: 12px !important;
}
.rap-controls .rap-pin {
  top: -4px !important;
}
.rap-volume-controls .rap-pin {
  left: -3.1px !important;
}
.rap-volume-controls .rap-slider {
  width: 4px !important;
}
.rap-container {
  box-shadow: none !important;
}
.rap-controls {
  position: relative;
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.rap-current-time {
  font-size: 12px;
  position: absolute;
  bottom: -24px;
  left: 12px;
}
.rap-total-time {
  font-size: 12px;
  position: absolute;
  bottom: -24px;
  right: 12px;
}

/* .main {
  padding-top: 56px;
} */

.h-100 {
  height: 100vh;
}
.w-100 {
  width: 100vw;
}
.w-33 {
  width: 33vw;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}

.m-auto {
  margin: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}


.floating-toolbar {
  position: fixed;
  bottom: -100px;
  right: 0;
  left: 312px;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  width: 548px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background: white;
  box-shadow: 0px 4px 26px -2px #151f2020; 
  z-index: 10001;
  transition: 0.5s ease-in-out all;
}

.floating-toolbar-show {
  bottom: 56px;
}

.whiteboardView .button-initial {
    background-color: white; /* White background */
    color: black;           /* Black text */
    border-radius: 10px;  
    font-weight: 400; 
    font-size: 12; 
    padding-left: 11vh;
    padding-right: 11vh;
    text-align: center;
    text-transform: none /* Rounded corners, adjust as needed */
  }
  
.whiteboardView .button-active {
    background-color: #66C097; /* Green background */
    color: #fff;    
    font-weight: 400; 
    font-size: 12; 
    padding-left: 10vh;
    padding-right: 10vh;        /* Black text */
    border-radius: 10px;  
    text-transform: none /* Rounded corners, adjust as needed */    /* Rounded corners, adjust as needed */
}

.whiteboardView .button-active-mute {
  background-color: #66C097; /* Green background */
  color: #fff;    
  font-weight: 400; 
  font-size: 12; 
  padding-left: 10vh;
  padding-right: 10vh;        /* Black text */
  border-radius: 10px;  
  margin-left: 3vh;
  text-transform: none /* Rounded corners, adjust as needed */    /* Rounded corners, adjust as needed */
}

.whiteboardView .button-error {
  background-color: #FF0000; /* Error red background */
  color: #fff;    
  font-weight: 400;
  margin-left: 3vh; 
  font-size: 12; 
  padding-left: 10vh;
  padding-right: 10vh;        /* Black text */
  border-radius: 10px;  
  text-transform: none /* Rounded corners, adjust as needed */    /* Rounded corners, adjust as needed */
}

.whiteboardView .syncWhiteboard {
    background-color: black; /* White background */
    color: white;          /* Black text */
    border-radius: 8px; 
    border-color: #fff;
    font-weight: 400; 
    font-size: 12; 
    padding-left: 10vh;
    padding-right: 10vh;  
    margin-top: 1px;      /* Black text */
    border-radius: 10px;  
    text-transform: none /* Rounded corners, adjust as needed */
}


.whiteboardView .button-waitlist {
  background-color: black; /* White background */
  color: white;           /* Black text */
  border-radius: 10px;  
  font-weight: 400; 
  font-size: 12; 
  padding-left: 12vh;
  padding-right: 12vh;
  text-align: center;
  text-transform: none; /* Rounded corners, adjust as needed */
  border: 1px solid #fff;
  margin-left: 3vh; 
}

.whiteboardView .button-sync {
  background-color: white; /* White background */
  color: black;           /* Black text */
  border-radius: 10px;  
  font-weight: 400; 
  font-size: 12; 
  padding-left: 12vh;
  padding-right: 12vh;
  text-align: center;
  text-transform: none; /* Rounded corners, adjust as needed */
  border: 1px solid #fff;
  margin-left: 3vh; 
}

.headerModified {
    color:#fff;
    text-align: left;
    padding-left: 30px; 
    font-weight: 400;
}

.excalidraw-custom .excalidraw.theme--dark {
  --color-primary:#181818;
  min-height: 80vh;
  border-radius: 4px;
}

#chat::-webkit-scrollbar {
  width: 10px;
  background: #1E1E1E;
}

#chat::-webkit-scrollbar-track {
  background: #1E1E1E; 
}

#chat::-webkit-scrollbar-thumb {
  background: #888; 
}

#chat::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

  /* Testing Excalidraw override  .Excalidraw {
    --color-primary: blue!important;
    --color-primary-darker: blue !important;
    --color-primary-darkest:blue !important;
    --color-primary-light: blue !important;
    --default-bg-color: blue !important;
    min-height: 60vh;
    border: 5px solid yellow; 
} */

.space-mono-regular {
  font-family: "Mulish", monospace;
  font-weight: 400;
  font-style: normal;
}

.space-mono-regular-italic {
  font-family: "Mulish", monospace;
  font-weight: 400;
  font-style: italic;
}

.space-mono-bold { 
  font-family: "Mulish", monospace;
  font-weight: 700;
  font-style: normal;
}

.space-mono-bold-italic {
  font-family: "Mulish", monospace;
  font-weight: 700;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bold {
  font-weight: 600;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.interviewRow {
  font-family: 'PPNeueMontreal';
}

.clickableRow:hover, .clickableRowSelected {
  background-color: var(--color-primary-bg-hover);
  cursor: pointer;
}

.disabledRow {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.btn {
  cursor: pointer !important;
  background: #20C58A !important;
  text-transform: none !important;
  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 6px !important;
  font-family: 'PPNeueMontreal' !important;
  color: white !important;
  border: none !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.black {
  background: black !important;
  color: white !important;
}

.black:hover {
  background: rgb(50, 50, 50) !important;
  color: white !important; 
  border: 1px solid black !important;
}

.btn-icon {
  cursor: pointer !important;
  background-color: transparent !important;
  border: none !important;
  transition: all .2s;
}

.btn-main {
  cursor: pointer !important;
  background-color: var(--color-main) !important;
  text-transform: none !important;
  padding: 6px 8px;
  border-radius: 8px !important;
  font-family: 'PPNeueMontreal' !important;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  color: white !important;
  border: none !important;
  font-weight: 500 !important;
  transition: background-color .2s;
}

.btn-main.thin {
  height: 24px;
  padding: 2px 8px;
  font-size: 12px;
}

.btn-main.small {
  height: 24px;
  padding: 3px 4px;
}

.btn-main.action {
  background-color: var(--color-action) !important;
}
.btn-main.action:hover {
  background-color: var(--color-action-light) !important;
}

.btn-main:hover {
  background-color: var(--color-main-light) !important;
}

.btn-dark-mode:hover {
  background-color: var(--color-hover-dark) !important;
}

.btn-dark-mode-sidebar {
  background-color: var(--color-hover-dark) !important;
}

.btn-dark-mode-sidebar:hover {
  background-color: var(--color-hover-dark-light) !important;
}

.btn-logo {
  border-radius: 0 !important;
}

.btn-upload {
  cursor: pointer !important;
  background-color: var(--color-lightgray) !important;
  text-transform: none !important;
  padding: 12px;
  border-radius: 8px !important;
  font-family: 'PPNeueMontreal' !important;
  font-size: 14px;
  line-height: 20px;
  height: 'fit-content' !important;
  color: var(--color-gray) !important;
  border: 1px dashed lightgray !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  transition: background-color .2s;
}

.btn-upload:hover {
  background-color: var(--color-midgray) !important;
  height: 'fit-content' !important;
  color: var(--color-gray) !important;
  box-shadow: none !important;
}

.btn-main.assistant {
  color: #fff !important;
  background-color: var(--color-assistant) !important; 
}

.btn-main.assistant:hover:not([disabled]) {
  color: #fff !important;
  background-color: var(--color-assistant-dark) !important;
}

.btn-main.outlined:hover:not([disabled]) {
  color: #1a1a1a !important;
  background-color: #d4dce1 !important;
}

.btn-main.outlined.danger:hover:not([disabled]) {
  color: var(--color-lightgray) !important;
  background-color: var(--color-danger)!important;
}

.btn-main.btn-text {
  background-color: transparent !important;
  color: #1a1a1a !important;
  font-weight: 600 !important;
}

.outlined.danger {
  color: var(--color-danger) !important;
  background: white !important;
  border: 1px solid var(--color-danger) !important;
  transition: background-color .2s;
}

.outlined.danger:hover {
  color: white !important;
  background: red !important;
  border: 1px solid white !important;
  border: none !important;
}

.outlined.gray {
  background-color: white !important;
  border: 1px solid lightgray !important;
  font-weight: 600 !important;
}

.outlined.gray:hover:not([disabled]) {
  background-color: lightgray !important;
}

.btn:disabled {
  cursor: not-allowed !important;
  background: #B2B2B2 !important;
  pointer-events: none !important;
}

.btn-main:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}


structuredOutput {
  float: right;
}

.btn:hover {
  background-color: #17A974;
}

.btn.gray:hover {
  color: #1a1a1a !important;
  background-color: #d7dee4 !important;
}

.btn:active {
  background-color: #128C5E !important;
}

.btn.reverse {
  background: white !important;
  color: #20C58A !important;
}

.btn.reverse:hover {
  background: #ECF5FB !important;
  color: white !important;
}


.outlined {
  background-color: #ECF5FB !important;
  color: #1a1a1a !important;
}

.clear {
  background-color: #fff !important;
  border: none !important;
}

.clear:hover {
  background-color: #ECF5FB !important;
  border: none !important;
}

.outlined:disabled {
  cursor: not-allowed !important;
  color: gray !important;
  /* border: none !important; */
}

.outlined:hover {
  color: white !important;
}

.settings {
  background: white;
  border: 1px solid black;
  color: black;
}

.settings:hover {
  color: white;
  background: black;
}


.left-label-wrapper {
  padding-right: 12px;
}

.in-progress-chip {
  background-color: black;
  color: #fff;
}

/* stepperStyles.css */
.MuiStepConnector-line {
  border-top-width: 3px; /* Sets the thickness of the connector lines */
  border-color: #20C58A; /* Sets a custom color for the connector lines */
}

.no-border .MuiOutlinedInput-notchedOutline {
  border: none;
}


.interview-list-wrapper::before {
  position: absolute;
  height: calc(100% - 32px);
  left: -1px;
  border-left: 2px dashed var(--color-hover-dark);
  content: '--';
}
.interview-list-item {
  margin-left: 12px !important;
  position: relative;
  border: 1px solid transparent;
  transition: all .2s;
}
.interview-list-item-v2 {
  background-color: var(--color-assistant-dark) !important;
}
.interview-list-item::before {
  position: absolute;
  left: -14px;
  top: -5px;
  content: '---';
  border: 2px dashed var(--color-hover-dark);
  border-radius: 2px;
  border-right: none;
  border-left: none;
  border-top: none;
  transition: all .2s;
  color: transparent;
  opacity: 1;
}
.interview-list-item:hover::before {
  opacity: 0;
}

.interview-list-item-selected, .interview-list-item:hover { 
  margin-left: 8px !important;
  border: 1px solid transparent;
  color: white !important;
  background-color: var(--color-hover-dark);
  border-radius: 6px !important;
  box-shadow: 0px 1px #151F2014;
  cursor: pointer;
}

.interview-list-item-selected-v2, .interview-list-item-selected-v2:hover {
  background-color: var(--color-assistant) !important;
}

.team-selected { 
  background-color: var(--color-primary-bg) !important;
  cursor: default;
}

.interview-list-item-selected p, .interview-list-item:hover p, .interview-list-item-selected svg, .interview-list-item:hover svg { 
  color: white !important;
}

.interview-list-item-selected .outlined.gray-dark, .interview-list-item:hover .outlined.gray-dark { 
  background-color: var(--color-hover-dark) !important;
}

.sidebar-list-wrapper {
  transition: all .3s ease;
  max-height: 40vh; 
  scrollbar-color: rgba(255, 255, 255, 0.2) var(--color-dark-mode); 
}

@media (max-height: 768px) {
  .sidebar-list-wrapper {
    /* rely on flexbox for height on smaller screens */
    flex: 1;
  }
}

.sidebar-list-item {
  padding: 6px 6px 6px 12px;
  border-radius: 6px !important;
  height: 32px;
  margin: 2px 0px;
  width: 222px !important;
}

.nav-item-wrapper {
  margin: 0px 8px 4px;
  padding: 8px;
  border: 1px solid transparent;
  border-radius: 6px;
  height: 36px;
  cursor: pointer;
  transition: all .2s;
}
.nav-item-wrapper:hover {
  margin-left: 12px !important;
  border: 1px solid transparent;
  color: white !important;
  background-color: var(--color-hover-dark) !important;
  box-shadow: 0px 1px #151F2014;
}

.nav-item-wrapper svg {
  font-size: 18px;
  margin-right: 12px;
  color: var(--color-dark-mode-light);
  transition: all .2s;
}
.nav-item-wrapper h4 {
  font-size: 14px;
  line-height: 19px;
  margin: 0;
  color: var(--color-dark-mode-light);
  transition: all .2s;
  font-weight: 500;
}

.nav-item-wrapper.selected svg, .nav-item-wrapper.selected h4, .nav-item-wrapper:hover svg, .nav-item-wrapper:hover h4 {
  color: #fff;
}

.beta-badge {
  background-color: var(--color-primary);
  padding: 2px 10px;
  border-radius: 4px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  
  color: #000;
  font-size: 10px;
  font-weight: bold;
}

.getting-started {
  margin-left: 12px !important;
  position: relative;
  border: 1px solid transparent;
  transition: all .2s;
}

.getting-started:hover {
  margin-left: 8px !important;
  border: 1px solid transparent;
  color: white !important;
  background-color: var(--color-hover-dark) !important;
  border-radius: 6px !important;
  box-shadow: 0px 1px #151F2014;
  cursor: pointer;
}

.getting-started:hover p, .getting-started:hover svg {
  color: white !important;
}

.outlined.gray-dark {
  background-color: var(--color-dark-mode) !important;
  font-weight: 600 !important;
}
.outlined.gray-dark:hover:not([disabled]) {
  color: white;
  background-color: var(--color-dark-mode) !important;
}

.link:hover {
  padding: 0px;
  cursor: pointer;
}

.back:hover {
  cursor: pointer;
}

.copyBtn:hover {
  background: #e3e3e3 !important;
}

.drop-shadow-btn {
  box-shadow: 0px 3px 6px -2px #266DF014,
              0px 2px 4px -2px #266DF01F,
              0px 0px 0px 1px #2325291A inset;
}

.blurred {
  filter: blur(4px);
}

.active-link:hover {
  cursor: pointer;
}
.non-interactive {
  pointer-events: none;
  user-select: none;
}

.radio-btn:hover {
  cursor: pointer;
  border: 1px solid black !important;
}

.btn-inverted {
  cursor: pointer !important;
  background-color: white !important;
  text-transform: none !important;
  border-radius: 9px !important;
  font-family: 'PPNeueMontreal' !important;
  font-size: 14px;
  line-height: 20px;
  height: 32px;
  border: 1px solid black !important;
  font-weight: 500 !important;
  color: black !important;
  transition: background-color .2s;
}

.btn-inverted.small {
  height: 24px;
  padding: 3px 4px;
}

.btn-inverted:hover {
  background-color: lightgray !important;
}

.btn-inverted.outlined:hover:not([disabled]) {
  color: #1a1a1a !important;
  background-color: #d4dce1 !important;
}

.btn-inverted.outlined.danger:hover:not([disabled]) {
  color: var(--color-lightgray) !important;
  background-color: var(--color-fail)!important;
}

.video-recorder-select:hover {
  cursor: pointer;
  box-shadow: 0px 1px #151F2014; 
}